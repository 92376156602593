import Vue from 'vue'

export const globalState = Vue.observable({
  isCN: false
})

export const mutations = {
  setCNStatus(status) {
    globalState.isCN = status
  }
}