import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './permission'

Vue.config.productionTip = false

// 引入jQuery、bootstrap
import $ from 'jquery'
import 'bootstrap'

// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './assets/css/style.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'waypoints/lib/noframework.waypoints.min.js'
import { globalState } from './utils/global'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

Vue.use(Antd)
Vue.prototype.$message = message
message.config({
  duration: 2, // 持续时间
  top: `65px`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})
Vue.prototype.global = globalState
// 全局注册 $
Vue.prototype.$ = $

// 路由切换时手动埋点
router.beforeEach(async (to, from, next) => {
  if (to.path) {
     if (window._hmt) {
          window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next(); 
}); 

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
